import React from 'react';

export const ImagePreview = ({ dataUri }:any) => {
  
    return (
      <div className={'demo-image-preview '}>
        <img src={dataUri} alt='img'/>
      </div>
    );
  };

  
  export default ImagePreview;