import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./App.css";
import "./scss/index.scss";
import { ZoomMtg } from "@zoom/meetingsdk";
import {  useNavigate, useParams } from "react-router-dom";
import Camera from "react-html5-camera-photo";
import ImagePreview from "./ImagePreview";
import "react-html5-camera-photo/build/css/index.css";
import Feedback from "./Feedback";
import {isMobile} from 'react-device-detect';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("vi-VN");
ZoomMtg.i18n.reload("vi-VN");

function Customer2() {
  let { hash } = useParams();
  const [userInfo, setUserInfo] = useState<any>();
  const history = useNavigate();
  const [modalFeedback, setModalFeed] = useState<boolean>(false);

  const [statusMeeting, setStatusMeeting] = useState<any>("");
  const [uuidZoom, setuuidZoom] = useState<any>("");
  const [dataUri, setDataUri] = useState("");
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [frontCamera, setFrontCamera] = useState<any>(null);
  
  interface PermisionProps {
    location: boolean;
    zoom: boolean;
  }

  const initialPermisionProps: PermisionProps = {
    location: false,
    zoom: false,
  };

  const [msgSocket, setMsgSocket] = useState<any>([]);
  const [permision, setPermision] = useState<PermisionProps>(
    initialPermisionProps
  );

  var getInfoEndpoint = `${process.env.REACT_APP_API_URL}dev-customer-api/join`;
  var registrantToken = "";
  useEffect(() => {
    if (hash && isMobile) {
      axios
      .get(`${getInfoEndpoint}/${hash}`,{
        headers: { "Content-Type": "application/json" }
      })
      .then((response:any) => {        
        if (response?.data?.message) {
          window.location.href = "/home";
        } else {
          console.log('response',response)
          setUserInfo(response?.data);
          startMeeting(response?.data);
        }
      })
      .catch((error) => {
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash,isMobile]);

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  // var sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;

  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  

  var el =
    (document.querySelector(".send-video-container__btn") as HTMLElement) ||
    null;

  function closeHost(): void {
    const btn = document.querySelector(
      '[aria-label="Giữ Người tham gia"]'
    ) as HTMLDivElement | null;
    closeWarningHost()
    if (btn) {
      btn.click();
      closeWarningHost()
    }
    return;
  }

  function handleCloseAudio():void{
    const btn = document.querySelector('[aria-label="WiFi hoặc dữ liệu di động"]') as HTMLDivElement;
    if (btn) {
      const parentPopup = btn.parentNode?.parentNode?.parentNode?.parentNode as HTMLDivElement;
      btn.click();
       parentPopup.style.display = 'none';
    }
    return;
  }
  function closeWarningHost():void{
      let button = document.querySelector('[aria-label="Cuộc họp này đang được ghi lại"]') as HTMLDivElement;
      if (button) {
        (button.querySelector('.zm-btn-legacy') as HTMLDivElement)?.click()
      }
  }
  function closePopupRecord():void{
    const btn = document.querySelector('[aria-label="OK"]') as HTMLDivElement;
    console.log('btn',btn)
    if (btn) {
      btn.click();
    }
    return;
  } 
  function startMeeting(data: any) {
    document.getElementById("zmmtg-root")!.style.display = "block";
    ZoomMtg.init({
      leaveUrl: `${process.env.REACT_APP_WEB_URL}`,
      disableJoinAudio: false, //optional
      isSupportAV: true,
      disablePreview: true,
      patchJsMedia: true,
      success: (success: any) => {
        ZoomMtg.join({
          signature: data.signature,
          meetingNumber: data.meetingNumber,
          userName: data.phone,
          sdkKey: data.sdkey,
          userEmail: "",
          passWord: data.passWord,
          tk: registrantToken,
          success: (success: any) => {
            ws.current.send(
              JSON.stringify({
                message: "Accept Zoom",
              })
            );
            
            setTimeout(() => {              
              handleCloseAudio()
              closePopup();
              closeHost();
              closePopupRecord()
            }, 3000);
            ZoomMtg.getCurrentMeetingInfo({
              success: function (res: any) {
                setuuidZoom(res.result.mid);
              },
            });
          },
          error: (error: any) => {
          },
        });
      },
      error: (error: any) => {
      },
    });
  }



  ZoomMtg.inMeetingServiceListener("onMeetingStatus", function (data: any) {
    setStatusMeeting(data.meetingStatus);
    if(data?.meetingStatus === 3) {
      setModalFeed(true);
    }
  });


  // check location

  const ws = useRef<any>(null);

  useEffect(() => {
    if (hash && isMobile) {
      ws.current = new WebSocket(`wss:graph.siptrunk.vn/ws/chat/${hash}/`);
      ws.current.onmessage = (e: any) => {
        const message = JSON.parse(e.data);
        setMsgSocket((prev: any) => [...prev, message]);
      };
    }
  }, [hash,isMobile]);

  const getMessageWss = () => {
    let checkPermisionLoc = msgSocket.some(
      (item: any) => item.message === "Take Photo"
    );
    let checkPermisionZoom = msgSocket.some(
      (item: any) => item.message === "Take Photo"
    );
    const nextProps = {
      location: checkPermisionLoc,
      zoom: checkPermisionZoom,
    };
    setPermision(nextProps);
  };

  useEffect(() => {
    if (msgSocket) {
      getMessageWss();
    }
  }, [msgSocket]);

  useEffect(() => {
    if (permision.zoom === true) {
      setOpenCamera(true);
      handleSend();
      getMessageWss();
    }
  }, [permision.zoom]);

  const handleSend = () => {
    if (ws.current.readyState) {
      ws.current.send(
        JSON.stringify({
          message: "again",
        })
      );

      if (openCamera === true) {
        let btnTakePhoto = document.getElementById("inner-circle");
        btnTakePhoto?.click();
      } else {
        const filter = msgSocket.filter(
          (item: any) =>
            item.message !== "again" &&
            item.message !== "Take Photo" &&
            item.message !== "success"
        );
        setMsgSocket(filter);
      }
    } else {
      setTimeout(() => {
        handleSend();
      }, 1000);
    }
  };

  const getSuccess = () => {
    if (ws.current.readyState) {
      ws.current.send(
        JSON.stringify({
          message: "success",
        })
      );
      const filter = msgSocket.filter(
        (item: any) =>
          item.message !== "again" &&
          item.message !== "Take Photo" &&
          item.message !== "success"
      );
      setMsgSocket(filter);
    } else {
      setTimeout(() => {
        getSuccess();
      }, 1000);
    }
  };

  const handleGetLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const newUserPos = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        };
        ws.current.send(
          JSON.stringify({
            message: "Share Geolocation",
          })
        );

        axios
          .post(
            `${process.env.REACT_APP_API_URL}customer-api/update/${hash}`,
            newUserPos
          )
          .then((res) => {})
          .catch((error) => {
          });
      },
      function (e) {
        ws.current.send(
          JSON.stringify({
            message: "Permision Denied Location",
          })
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function handleTakePhotoAnimationDone(dataUri: any) {
    // Do stuff with the photo...
    setDataUri(dataUri);
    openMainCamera()
    if(dataUri){
      getSuccess();
      handleSubmit(dataUri)
    }

  }

  const handleSubmit = async (data:string) => {
    // generate file from base64 string
    let formData = new FormData();
    formData.append("image", data);

    axios
      .post(`${process.env.REACT_APP_API_URL}api/upload/${hash}`, formData)
      .then((res) => {
        if (res.status === 200) {
        }
        if (el) {
          el.click();
          setTimeout(() => {
            openMainCamera()
          }, 1000);
        }

      })
      .catch((error) => {
        if (el) {
          el.click();
          setTimeout(() => {
            openMainCamera()
          }, 1000);
        }

      });
  };

// close popup
function closePopup(): void {
  handleGetLocation();
  openMainCamera()
}
// click open camera when done

function openMainCamera(): void {
const btn = document.querySelector(
  '[aria-label="start my video"]'
) as HTMLDivElement | null;
if (btn) {
  console.log('btn',btn)
  btn?.click();
}
return;
}


  
  return (
    <div className="App">
      {modalFeedback && <Feedback hash={userInfo.phone} uuidZoom={uuidZoom} />}
      {dataUri && <ImagePreview dataUri={dataUri} />}
      <main>
        {isMobile === false && (
          <div className="modal-popup_style2 open">
            <div className="popup-container pu-permision">
              <div className="content text-center">
                <h3 className="title">Thiết bị không có quyền truy cập</h3>
                <div className="content">
                  Cuộc gọi videocall được thực hiện trên thiết bị di động. Vui lòng truy cập đường dẫn qua tin nhắn trên điện thoại
                </div>
                <div className="btn-g">
                  <span
                    className="btn btn-permision"
                    onClick={() => history('/home')}
                  >
                    Quay lại trang chủ
                  </span>
                  
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="box-camera active" style={{ zIndex: 0 }}>
          {openCamera && (
            <>
              <Camera
                idealFacingMode={frontCamera}
                onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
              />
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default Customer2;
