import React from "react";
import { Link } from "react-router-dom";
export const Home = () => {
  return (
    <>
      <div className="header header-sticky">
        <div className="container">
          <div className="logo">
            <Link to={"/khach-hang/"}>
              <span className="logo-icon"></span>
            </Link>
          </div>
          <ul className="navbar_menu pushmenu js-menubar">
            <li className="level1">
              <a href="#1" title="">
                Ứng Dụng Mcredit
              </a>
            </li>
            <li className="level1">
              <a href="#2" title="">
                Vay Tiền Mặt
              </a>
            </li>
            <li className="level1">
              <a href="#3" title="">
                Vay Trả Góp
              </a>
            </li>
            <li className="level1">
              <a href="#4" title="">
                Thẻ Tín Dụng
              </a>
            </li>
          </ul>
          <span className="btn btn-contact">
            <span className="text">Đăng ký vay</span>
          </span>
          <span className="btn btn-menu js-click-menu">
            <i className="cicon-menu"></i>
          </span>
        </div>
      </div>
      <div className="section-banner">
        <div className="photo">
          <img
            src="https://mcredit.com.vn/content/133317896413908710_momo-t6_homepage.jpg"
            alt="abc"
          />
        </div>
      </div>
    </>
  );
};

export default Home;
