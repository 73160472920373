/** @format */

import React from "react";


import Customer from "./Customer";
import Home from "./Home";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
function App() {
  return (
    <div className="App">
       <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/khach-hang" replace />}>
          </Route>
          <Route path="/home"  element={<Home />} />
          <Route path="/khach-hang/:hash"  element={<Customer/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
